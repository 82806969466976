module.exports = [{
      plugin: require('../plugins/@slnsw/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"Type","store":true},{"name":"RelevantLanguageCode","store":true},{"name":"Title","store":true,"attributes":{"boost":10}},{"name":"ID"},{"name":"Slug","store":true}],"resolvers":{"CardsCsv":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"},"includeOptions":{"include":["^/search$","^/404$"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://scanned-card-catalogue.sl.nsw.gov.au","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MGDZTDR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
