// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tips-tsx": () => import("./../../../src/pages/tips.tsx" /* webpackChunkName: "component---src-pages-tips-tsx" */),
  "component---src-templates-card-tsx": () => import("./../../../src/templates/card.tsx" /* webpackChunkName: "component---src-templates-card-tsx" */),
  "component---src-templates-letter-tsx": () => import("./../../../src/templates/letter.tsx" /* webpackChunkName: "component---src-templates-letter-tsx" */)
}

